import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';  // قم باستيراد ScrollToTop
import CryptoDashboard from './CryptoDashboard';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from './pages/Home';
import Services from './pages/Services';
import Indicators from './pages/Indicators';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import TermsConditions from './pages/TermsConditions';


function App() {
  return (
    <Router>
      <ScrollToTop /> {/* إضافة ScrollToTop هنا */}
      <Navbar />

      <Routes>
        {/* اجعل CryptoDashboard هو الصفحة الرئيسية */}
        <Route path="/" element={<CryptoDashboard />} />
        
        {/* توجيه مسارات متعددة إلى CryptoDashboard */}
        <Route path="/cir9bucket" element={<CryptoDashboard />} />
        <Route path="/Home" element={<CryptoDashboard />} />

        {/* بقية المسارات */}
        <Route path="/services" element={<Services />} />
        <Route path="/indicators" element={<Indicators />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/TermsConditions" element={<TermsConditions />} />

        {/* توجيه لأي مسار غير معروف إلى الصفحة الرئيسية */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
