import React from 'react';
import './Indicators.css';

function Indicators() {
  return (
    <div className="indicators-container">
      <div className="indicators-header">
        <h1>Indicators</h1>
      </div>
      <div className="intro-text">
        <p>
          At CIR-9, we provide precise analysis using a set of essential technical indicators that help you make fast and informed trading decisions. Based on the analysis of these indicators, buy, sell, or hold signals are generated according to the market condition. Here’s a list of the indicators we use:
        </p>
      </div>
      <div className="indicator-item">
        <h2>1. MACD (Moving Average Convergence Divergence)</h2>
        <p>
          Description: MACD is one of the most popular momentum indicators that measures the difference between short-term and long-term moving averages, helping identify market turning points.
        </p>
        <p><strong>Buy Signal:</strong> When the MACD line crosses above the signal line.</p>
        <p><strong>Sell Signal:</strong> When the MACD line crosses below the signal line.</p>
        <p><strong>Hold Signal:</strong> When the two lines cross, and the market is in a consolidation phase.</p>
      </div>
      <div className="indicator-item">
        <h2>2. RSI (Relative Strength Index)</h2>
        <p>
          Description: RSI measures the speed and magnitude of price movements to determine overbought and oversold conditions in the market.
        </p>
        <p><strong>Buy Signal:</strong> When RSI is below 30 and starts rising (indicating an oversold market).</p>
        <p><strong>Sell Signal:</strong> When RSI is above 70 and starts declining (indicating an overbought market).</p>
        <p><strong>Hold Signal:</strong> When RSI is between 30 and 70, indicating a neutral market.</p>
      </div>
      <div className="indicator-item">
        <h2>3. OBV (On-Balance Volume)</h2>
        <p>
          Description: OBV connects trading volume with price movements to measure the strength of trends and to forecast potential market direction.
        </p>
        <p><strong>Buy Signal:</strong> When OBV rises alongside price increases.</p>
        <p><strong>Sell Signal:</strong> When OBV declines while prices are rising, indicating weakening momentum.</p>
        <p><strong>Hold Signal:</strong> When OBV is flat or shows little change.</p>
      </div>
      <div className="indicator-item">
        <h2>4. EMA (Exponential Moving Average)</h2>
        <p>
          Description: EMA gives more weight to recent prices compared to the simple moving average (SMA), helping track trends faster.
        </p>
        <p><strong>Buy Signal:</strong> When a short-term EMA crosses above a long-term EMA.</p>
        <p><strong>Sell Signal:</strong> When a short-term EMA crosses below a long-term EMA.</p>
        <p><strong>Hold Signal:</strong> When both EMAs are moving in the same direction without significant changes.</p>
      </div>
      <div className="indicator-item">
        <h2>5. Bollinger Bands</h2>
        <p>
          Description: Bollinger Bands measure market volatility and define upper and lower price boundaries based on the standard deviation of price movements.
        </p>
        <p><strong>Buy Signal:</strong> When the price bounces off the lower Bollinger Band.</p>
        <p><strong>Sell Signal:</strong> When the price bounces off the upper Bollinger Band.</p>
        <p><strong>Hold Signal:</strong> When the price stays within the bands without a significant breakout.</p>
      </div>
      <div className="indicator-item">
        <h2>6. VWAP (Volume Weighted Average Price)</h2>
        <p>
          Description: VWAP measures the average price during the day weighted by trading volume, helping determine the market trend based on traded volume.
        </p>
        <p><strong>Buy Signal:</strong> When the price is above VWAP.</p>
        <p><strong>Sell Signal:</strong> When the price is below VWAP.</p>
        <p><strong>Hold Signal:</strong> When the price fluctuates around VWAP.</p>
      </div>
      <div className="indicator-item">
        <h2>7. ADX (Average Directional Index)</h2>
        <p>
          Description: ADX measures trend strength and helps identify whether the market is in a strong or weak trend.
        </p>
        <p><strong>Buy Signal:</strong> When ADX is above 25 and the main trend is moving upwards.</p>
        <p><strong>Sell Signal:</strong> When ADX is above 25 and the main trend is moving downwards.</p>
        <p><strong>Hold Signal:</strong> When ADX is below 25, indicating no clear market direction.</p>
      </div>
      <div className="indicator-item">
        <h2>8. Stochastic RSI</h2>
        <p>
          Description: This indicator combines the Stochastic and RSI to measure momentum and overbought/oversold conditions.
        </p>
        <p><strong>Buy Signal:</strong> When Stochastic RSI is below 20 and starts rising.</p>
        <p><strong>Sell Signal:</strong> When Stochastic RSI is above 80 and starts declining.</p>
        <p><strong>Hold Signal:</strong> When Stochastic RSI is between 20 and 80, indicating a neutral condition.</p>
      </div>
      <div className="indicator-item">
        <h2>9. ATR (Average True Range)</h2>
        <p>
          Description: ATR measures market volatility by calculating the average price range over a set period.
        </p>
        <p><strong>Buy/Sell Signal:</strong> When ATR rises significantly, indicating increased volatility.</p>
        <p><strong>Hold Signal:</strong> When ATR is stable or low, indicating low volatility.</p>
      </div>
      
    </div>
  );
}

export default Indicators;
