import React, { useState } from 'react';
import './Contact.css';
function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., API call)
    alert("Thank you for contacting us! We'll get back to you soon.");
    setFormData({ name: '', email: '', message: '' }); // Reset form
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <h1>Contact Us</h1>
        <p>At CIR-9, we are committed to providing continuous support to our users. Feel free to reach out to us through the following methods:</p>
      </div>

      <div className="contact-methods">

      <div className="contact-method">
          <h2>Phone Number</h2>
          <p>If you would like to reach out to our CEO, you can contact him directly:</p>
          <p><strong>Eng. MHD Nassoh Akbik</strong></p>
          <p><a href="tel:+971568132133" className="phone-link">+971 56 813 2133</a></p>
        </div>
        <div className="contact-method">
          <h2>Email</h2>
          <p>If you have any inquiries or need assistance, you can send us an email at:</p>
          <p><a href="mailto:support@cir9.com" className="email-link">support@cir9.com</a></p>
        </div>

        <div className="contact-method">
          <h2>Contact Form</h2>
          <p>If you prefer to reach out through our contact form, please fill it out below:</p>
          <form onSubmit={handleSubmit} className="contact-form">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <button type="submit" className="submit-btn">Send Message</button>
          </form>
        </div>

        <div className="contact-method">
          <h2>Social Media</h2>
          <p>Stay connected with us on our social media platforms:</p>
          <ul>
            <li><a href="https://twitter.com/CIR9_Twitter" target="_blank" rel="noopener noreferrer" className="social-link">Twitter: @CIR9_Twitter</a></li>
            <li><a href="https://facebook.com/CIR9_Facebook" target="_blank" rel="noopener noreferrer" className="social-link">Facebook: CIR-9 Facebook</a></li>
            <li><a href="https://linkedin.com/company/CIR9_Linkedin" target="_blank" rel="noopener noreferrer" className="social-link">LinkedIn: CIR-9 LinkedIn</a></li>
          </ul>
        </div>

        <div className="contact-method">
          <h2>Address</h2>
          
          <p>Dubai , United Arab Emirates.</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
