import React, { useEffect } from 'react';

const AdComponent = () => {
  useEffect(() => {
    // تأكد من أن الإعلان لم يتم تحميله مسبقًا
    const existingAds = document.querySelectorAll('.adsbygoogle');
    if (existingAds.length === 0) {
      // تحميل سكربت Google AdSense بشكل غير متزامن
      const script = document.createElement('script');
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5104560025888020";
      script.async = true;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);

      // إضافة الإعلان بعد تحميل السكربت
      script.onload = () => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      };
    }

    return () => {
      // تنظيف السكربت عند إلغاء تحميل المكون
      const script = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5104560025888020"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-format="fluid"
           data-ad-layout-key="-fb+5p+5g-cs+41"
           data-ad-client="ca-pub-5104560025888020"  // استبدل هذا بمعرف العميل الخاص بك
           data-ad-slot="9750176208"></ins>  
    </div>
  );
};

export default AdComponent;
