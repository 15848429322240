import React from 'react';
import './Services.css'; // تأكد من وجود ملف CSS لتطبيق التنسيقات

function Services() {
  return (
    <section className="services-container">
      <header className="services-header">
        <h1>Our Premium Services</h1>
        <p className="intro-text">
          CIR-9 empowers traders with advanced, AI-powered technical analysis tools for rapid, informed decisions in the fast-paced cryptocurrency market.
        </p>
      </header>

      <div className="service-item">
        <h2>Fast, Automated Analysis</h2>
        <p>
          Instantly analyze cryptocurrency indicators tailored for fast-paced traders. Our system continuously updates, providing you with real-time insights based on the latest market trends.
        </p>
      </div>

      <div className="service-item">
        <h2>Real-Time Indicator Values</h2>
        <p>
          Access real-time values for essential indicators like MACD, RSI, and Bollinger Bands. Each update delivers precise data to keep you in sync with market movements.
        </p>
      </div>

      <div className="service-item">
        <h2>Final Market Signal Percentages</h2>
        <p>
          Make quick decisions with a clear view of buy, sell, and hold signals based on continuously refreshed data. Our easy-to-read percentage breakdowns simplify decision-making.
        </p>
      </div>

      <div className="service-item">
        <h2>Free Access to All Features</h2>
        <p>
          For a limited time, all CIR-9 services are available at no cost, allowing you full access to our advanced tools. Don’t miss out on this opportunity!
        </p>
      </div>

      
    </section>
  );
}

export default Services;
