import React from 'react';
import './Privacy.css';

function Privacy() {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>Last Updated: November 11, 2024</p>
      
      <section>
        <h2>1. Introduction</h2>
        <p>
          CIR-9 is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
        </p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <h3>Personal Information</h3>
        <p>
          When you sign up or contact us, we may collect personal information, such as your name, email address, and other contact details.
        </p>
        
        <h3>Usage Data</h3>
        <p>
          We also collect information on how you access and interact with our platform, including IP addresses, browser type, pages visited, and time spent on the site.
        </p>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>
          We use your information to:
        </p>
        <ul>
          <li>Provide, operate, and maintain our services.</li>
          <li>Improve, personalize, and expand our services.</li>
          <li>Understand and analyze how you use our services.</li>
          <li>Communicate with you regarding updates, promotional offers, and other information.</li>
        </ul>
      </section>

      <section>
        <h2>4. Information Sharing and Disclosure</h2>
        <p>
          We may share your information with third-party service providers to help us operate and improve our services. These providers are bound by contractual obligations to keep your information confidential and secure.
        </p>
      </section>

      <section>
        <h2>5. Data Security</h2>
        <p>
          We implement appropriate security measures to protect your data. However, please be aware that no electronic transmission over the internet or storage method is 100% secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section>
        <h2>6. Your Data Protection Rights</h2>
        <p>
          Depending on your location, you may have certain rights regarding your personal data, including the right to access, correct, or delete your data. To exercise these rights, please contact us at <a href="mailto:support@cir9.com">support@cir9.com</a>.
        </p>
      </section>

      <section>
        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Any changes will be posted on this page, and if significant, we will provide a prominent notice on our site.
        </p>
      </section>

      <section>
        <h2>8. Contact Us</h2>
        <p>
          If you have any questions or concerns about our Privacy Policy, please contact us at:
        </p>
        <p>
          Email: <a href="mailto:support@cir9.com">support@cir9.com</a><br />
          Address:Dubai , United Arab Emirates.
        </p>
      </section>
    </div>
  );
}

export default Privacy;
