// src/Spinner.js

import React from 'react';
import './Spinner.css'; // Import CSS for spinner styles

const Spinner = () => {
    return (
        <div className="spinner">
            <div className="spinner-circle"></div>
        </div>
    );
};

export default Spinner;
