import React from 'react';
import './TermsConditions.css';

function TermsConditions() {
  return (
    <div className="terms-container">
      <h1>Terms & Conditions</h1>
      <p>Last Updated: November 11, 2024</p>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing and using CIR-9's website and services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services.
        </p>
      </section>

      <section>
        <h2>2. Use of Services</h2>
        <p>
          CIR-9 provides technical analysis tools and services for cryptocurrency trading. These tools are intended to assist with trading decisions, but do not guarantee specific outcomes or profits. You acknowledge and agree that all trading involves risk, and CIR-9 is not responsible for any losses that may result.
        </p>
      </section>

      <section>
        <h2>3. User Account</h2>
        <p>
          To access certain features of CIR-9, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and agree to notify us immediately if you suspect any unauthorized access to your account.
        </p>
      </section>

      <section>
        <h2>4. Intellectual Property</h2>
        <p>
          All content, software, and materials provided on CIR-9's website are protected by intellectual property laws. You may not reproduce, distribute, or otherwise use any content from CIR-9 without express written permission.
        </p>
      </section>

      <section>
        <h2>5. Limitations of Liability</h2>
        <p>
          CIR-9 is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services. This includes, but is not limited to, losses in cryptocurrency trading, loss of data, or other potential damages.
        </p>
      </section>

      <section>
        <h2>6. Disclaimer of Warranties</h2>
        <p>
          CIR-9’s services are provided "as is" and "as available." We do not warrant that our services will be uninterrupted, error-free, or meet your specific needs. You use CIR-9 at your own risk.
        </p>
      </section>

      <section>
        <h2>7. Changes to Terms</h2>
        <p>
          CIR-9 reserves the right to modify these Terms and Conditions at any time. Any changes will be posted on this page, and your continued use of our services constitutes acceptance of the modified terms.
        </p>
      </section>

      <section>
        <h2>8. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by the laws of [Your Country/State]. Any disputes arising out of or related to the use of our services will be subject to the exclusive jurisdiction of the courts in that region.
        </p>
      </section>

      <section>
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions about these Terms and Conditions, please contact us at:
        </p>
        <p>
          Email: <a href="mailto:support@cir9.com">support@cir9.com</a><br />
          Address:Dubai , United Arab Emirates.
        </p>
      </section>
    </div>
  );
}

export default TermsConditions;
