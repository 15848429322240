import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <div className="about-header">
        <h1>CIR-9</h1>
      </div>
      <div className="about-intro">
        <p>
          We are a platform specializing in providing accurate technical analysis for the cryptocurrency markets, powered by advanced technical indicators supported by artificial intelligence. Our goal is to empower traders to make fast and reliable trading decisions based on real-time, up-to-date data.
        </p>
        <p>
          By integrating technical indicators such as MACD, RSI, EMA, and more, we offer precise buy, sell, and hold signals based on the market conditions. We strive to simplify the analysis process and reduce the risks associated with trading in volatile cryptocurrency markets.
        </p>
        <p>
          CIR-9 is the perfect place for traders looking for an efficient and fast tool to help them make the right decisions at the right time. We believe in providing easy-to-understand, reliable technical analysis tools that allow you to make the most out of your trades.
        </p>
      </div>

      <div className="about-vision-mission">
        <div className="about-vision">
          <h2>Our Vision</h2>
          <p>
            We aim to be the first choice for cryptocurrency traders by providing innovative and accurate tools that help make decisions based on advanced and reliable technical analysis.
          </p>
        </div>

        <div className="about-mission">
          <h2>Our Mission</h2>
          <p>
            Our mission is to simplify trading in cryptocurrency markets by offering high-quality and precise technical analysis, empowering traders to make informed, swift decisions and stay ahead of the market.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
